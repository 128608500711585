/* React */
import React, { Fragment, useState } from "react";
import dynamic from "next/dynamic";
import clonedeep from "lodash.clonedeep";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import kebabcase from "lodash.kebabcase";
import { getClasses } from "@washingtonpost/front-end-utils";
import { useAppContext } from "fusion-context";
import { useBreakpoints } from "~/shared-components/BreakpointContext";

// Components
import { UnassignedZone } from "./components/Unassigned";

import {
  HPLayouts,
  HPLayoutSynonyms,
  layoutStyles
} from "./utilities/settings";

import {
  getGridInfo,
  getLayoutObj,
  getExtraRowInfo,
  getGridGapStyles,
  validateTopTable,
  initItemsPerFeatureMapAdjustments,
  computeItemsPerFeature,
  initItemsPerFeatureMapPerBp,
  getItemsPerFeatureMapPerBp,
  getItemsPerColumnPerBp,
  createChainGridObj,
  getHideClasses,
  getPositionClasses,
  getDividerClasses,
  getAllFeatureIdsByTablePerBp,
  getAllFeatureIdsPerBp,
  createIdLookup,
  createDesktopOrderingObj,
  getDesktopOrderingClasses,
  getLayoutObjPerBp,
  createGridObj,
  createRenderDataStructure,
  findMissingChildrenIds,
  findHiddenFeatureIndices,
  findUnwebbedFeatureIndices,
  getHiddenChainInfo,
  getLabelArrangement
} from "./utilities/data-transformation";
import {
  useSandwichLayoutContext,
  SandwichLayoutProvider
} from "~/shared-components/layouts/SandwichLayoutContext";
import { ChainProvider } from "~/shared-components/ChainContext";
import { TableLayoutProvider } from "~/shared-components/TableLayoutContext";
import {
  getLabelObj,
  getCtaLabelObj,
  getLinkGroupAttr
} from "./utilities/label-helper";
import { CompoundLabel } from "~/shared-components/story-card/_children/Label";
import { useSpartanChain } from "~/spartan-homepage/assembler-render";
import { TopTablePropTypes } from "~/proptypes/top-table";
import Warning from "~/shared-components/Warning";

const IllegalPlacementWarning = dynamic(() =>
  import("~/shared-components/Warning").then((m) => m.IllegalPlacementWarning)
);

const HiddenChain = dynamic(() => import("./components/HiddenChain"));
const HiddenSection = dynamic(() => import("./components/HiddenSection"));

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getTotalRowSpansPerTableColumn = ({
  customFields,
  tableKey,
  col,
  childProps
}) => {
  const featureIndicesInTableColumn = get(
    customFields,
    `${tableKey}${capitalize(col)}Ids`,
    ""
  )
    .split(",")
    .filter((n) => n)
    .map(Number);
  if (!featureIndicesInTableColumn && !featureIndicesInTableColumn.length)
    return 0;
  return childProps.reduce((total, feature, index) => {
    if (featureIndicesInTableColumn.includes(index + 1)) {
      return total + Number(feature.customFields.rowSpan || 0);
    }
    return total;
  }, 0);
};

const TopTableLabel = "Multi-Table Chain";

/**
 * Chain that allows you to wrap features in one, and give them column widths within it.
 * Allows for nice grids within grids that adhere to our design system.
 *
 * @param {object} props
 * @returns React component representing the default chain.
 */
const TopTable = ({
  style,
  customFields,
  children,
  childProps,
  context,
  className
}) => {
  const appContext = useAppContext();
  const { isAdmin, outputType } =
    context && context.isAdmin !== undefined ? context : appContext;
  const breakpoints = useBreakpoints();

  const bp = (breakpoints && breakpoints.bp) || "mx";

  const { layoutClasses, visitedLinks } = useSandwichLayoutContext();
  const spartanChain = useSpartanChain();
  const {
    ref,
    dataAttrs,
    interactions: { onClick, ...restOfInteractions },
    selectors: { styles: spartanAdminStyles, overlay, overlayLeft },
    showHelpers,
    showFeatureNames
  } = spartanChain;

  let {
    selectors: { ChainColumnDropTarget }
  } = spartanChain;

  ChainColumnDropTarget = ChainColumnDropTarget || (() => null);

  const { bottomSeparator = "Large", displayName = "no-name" } = customFields;

  const hideValidationMsg = true;

  let {
    layout: layoutName = "Tiling" // defaultValues do not seem to work in chains
  } = customFields;

  // NOTE: If the layoutName is bogus set it to "======" which returns {}
  // and gracefully (?) renders nothing. Before this change, the entire
  // page died.
  if (
    !HPLayouts.includes(layoutName) &&
    !HPLayouts.includes(HPLayoutSynonyms[layoutName])
  ) {
    layoutName = "======";
  }

  // NOTE: Use active breakpoint and enhance with divider info
  let GRID_INFO = getGridInfo({ bp, ...customFields });

  // NOTE: At this point, layoutName does not take into account TopStrip,
  // but it doesn't matter for its intended use
  const desktopOrderingObjInit = createDesktopOrderingObj(
    GRID_INFO,
    layoutName
  );

  // NOTE: itemsPerFeatureMap -
  //  A stateful running total of (
  //    init items [not stateful] +
  //    disqualified content (cuz duplicate or inadequate data) [stateful] +
  //    feed adjustment [stateful]
  //  )
  const itemsPerFeatureMapPerBpInit = initItemsPerFeatureMapPerBp(
    GRID_INFO,
    childProps,
    desktopOrderingObjInit,
    outputType
  );
  const itemsPerFeatureMapInit = itemsPerFeatureMapPerBpInit[bp];
  const [itemsPerFeatureMap, setItemsPerFeatureMap] = useState(
    clonedeep(itemsPerFeatureMapInit)
  );

  // START: Prepare adjustments
  const itemsPerFeatureMapInitialAdjustments =
    initItemsPerFeatureMapAdjustments(itemsPerFeatureMapInit);

  const [
    itemsPerFeatureMapFeedAdjustments,
    setItemsPerFeatureMapFeedAdjustments
  ] = useState(clonedeep(itemsPerFeatureMapInitialAdjustments));

  const [
    itemsPerFeatureMapDQedAdjustments,
    setItemsPerFeatureMapDQedAdjustments
  ] = useState(clonedeep(itemsPerFeatureMapInitialAdjustments));

  const itemsPerFeatureMaps = {
    itemsPerFeatureMapInit,
    itemsPerFeatureMapFeedAdjustments,
    itemsPerFeatureMapDQedAdjustments
  };
  // END: Prepare adjustments

  // NOTE: If table 0 (i.e. top strip) in use, layoutName will become `${layoutName} with top strip`
  let layoutObj = "";
  // NOTE: This syntax destuctures onto existing variables
  ({ layoutObj, layoutName } = getLayoutObj(
    layoutStyles,
    layoutName,
    HPLayoutSynonyms,
    customFields,
    itemsPerFeatureMap
  ));

  const desktopOrderingObj = createDesktopOrderingObj(GRID_INFO, layoutName);
  const desktopOrderingClasses = getDesktopOrderingClasses(desktopOrderingObj);

  const hiddenChainInfo = getHiddenChainInfo(desktopOrderingObj, customFields);

  const tableKeyRefs = {
    table0: React.useRef(),
    table1: React.useRef(),
    table2: React.useRef(),
    table3: React.useRef(),
    table4: React.useRef(),
    table5: React.useRef(),
    table6: React.useRef(),
    table7: React.useRef(),
    table8: React.useRef(),
    tableAd1: React.useRef(),
    table9: React.useRef(),
    allcontent: React.useRef()
  };

  // NOTE: Remove breakpoints from GRID_INFO when chain is configured to be hidden
  GRID_INFO = isAdmin
    ? GRID_INFO
    : GRID_INFO.reduce((acc, info) => {
        const hideChain = get(hiddenChainInfo[info.bp], "hide", false);
        if (!hideChain) acc.push(info);
        return acc;
      }, []);

  // NOTE: If chain is hidden at a given breakpoint, return null
  if (!GRID_INFO.length) return null;

  const hideChain = get(hiddenChainInfo[bp], "hide", false);

  const itemsPerFeatureMapPerBp = getItemsPerFeatureMapPerBp(
    GRID_INFO,
    childProps,
    itemsPerFeatureMap,
    itemsPerFeatureMaps,
    desktopOrderingObj,
    outputType,
    isAdmin
  );

  // NOTE: Because a feature can be a feed/query with more than one story,
  // itemsPerFeatureMap is stateful and needs to get updated by the features that do that.
  // FURTHER TODO: Re: deduping -- itemsPerFeatureMap might be able to solve this
  if (!isEqual(itemsPerFeatureMap, itemsPerFeatureMapPerBp[bp])) {
    setItemsPerFeatureMap(itemsPerFeatureMapPerBp[bp]);
  }

  // NOTE: Features that are DQed or render feeds report back how many items they contain
  // which affects the grid layout, dividers, styles, etc.
  const updateItemsPerFeatureMap = (id) => {
    const count = computeItemsPerFeature(id, itemsPerFeatureMaps);
    if (itemsPerFeatureMap[id] === count) return;
    setItemsPerFeatureMap({
      ...itemsPerFeatureMap,
      [id]: count
    });
  };

  const updateItemsPerFeatureMapFeedAdjustments = (id, count) => {
    // NOTE: count should greater than or equal to 0
    count = Math.max(count, 0);
    // NOTE: adjustment should be less than or equal to 0
    const adjustment = count - itemsPerFeatureMapInit[id];
    if (itemsPerFeatureMapFeedAdjustments[id] === adjustment) return;
    setItemsPerFeatureMapFeedAdjustments({
      ...itemsPerFeatureMapFeedAdjustments,
      [id]: adjustment
    });
    updateItemsPerFeatureMap(id);
  };

  const updateItemsPerFeatureMapDQedAdjustments = (id, adjustment) => {
    // NOTE: adjustment should be less than or equal to 0
    adjustment = Math.min(adjustment, 0);
    if (itemsPerFeatureMapDQedAdjustments[id] === adjustment) return;
    setItemsPerFeatureMapDQedAdjustments({
      ...itemsPerFeatureMapDQedAdjustments,
      [id]: adjustment
    });
    updateItemsPerFeatureMap(id);
  };

  // Reverse look up of location by flex feature id
  const idLookup = createIdLookup(layoutObj, customFields, itemsPerFeatureMap);

  // Number of items in each table.column for getting responsive styles
  const itemsPerColumnPerBp = getItemsPerColumnPerBp(
    GRID_INFO,
    idLookup,
    itemsPerFeatureMapPerBp
  );
  const itemsPerColumn = itemsPerColumnPerBp[bp];
  const layoutObjPerBp = getLayoutObjPerBp(
    GRID_INFO,
    customFields,
    layoutStyles,
    layoutName,
    HPLayoutSynonyms,
    layoutObj,
    itemsPerColumnPerBp,
    showHelpers,
    isAdmin
  );
  if (!isEqual(layoutObj, layoutObjPerBp[bp])) {
    layoutObj = layoutObjPerBp[bp];
  }

  // NOTE: Needed to pack the grid rows properly when one of the tables
  // spans multiple rows
  const { classNames: extraRowClassNames, styles: extraRowStyles } =
    getExtraRowInfo(GRID_INFO, layoutObj)[bp];
  const gridGapStyles = getGridGapStyles(layoutName);

  // All the ids the editor has sent in custom fields in a set of arrays (one array per table)
  // If layout has allcontent, arrays will have all ids except for
  // actively excluded content
  const allFeatureIdsByTablePerBp = getAllFeatureIdsByTablePerBp(
    GRID_INFO,
    layoutObjPerBp,
    customFields,
    itemsPerFeatureMapPerBp
  );
  const allFeatureIdsByTable = allFeatureIdsByTablePerBp[bp];

  // All the ids the editor has sent in custom fields in an array
  // If layout has allcontent, arrays will have all ids except for
  // actively excluded content
  const allFeatureIdsPerBp = getAllFeatureIdsPerBp(
    GRID_INFO,
    allFeatureIdsByTablePerBp
  );
  const allFeatureIds = allFeatureIdsPerBp[bp];

  const chainGridObj = createChainGridObj(
    GRID_INFO,
    layoutObjPerBp,
    customFields
  );

  // NOTE: putting the "return null"s after last use of a hook
  /** ***************** START: IMPORTANT ****************** */
  // NOTE: return null if no content to show
  // if (allFeatureIds.length === 0) return null;
  const hasItemsAtSomeBreakpoint =
    Object.keys(allFeatureIdsPerBp).reduce(
      (acc, key) => acc + allFeatureIdsPerBp[key].length,
      0
    ) > 0;
  if (!hasItemsAtSomeBreakpoint) return null;
  /** ***************** END: IMPORTANT ****************** */

  const gridObj = createGridObj(
    GRID_INFO,
    children,
    childProps,
    layoutObjPerBp,
    allFeatureIdsByTablePerBp,
    idLookup,
    itemsPerFeatureMapPerBp,
    itemsPerColumnPerBp,
    desktopOrderingObj,
    customFields
  );

  // create chain and table labels
  const labelObj = getLabelObj({ customFields, isAdmin });

  // create chain and table cta labels
  const ctaLabelObj = getCtaLabelObj({ customFields, isAdmin });
  const hasChainCta = ctaLabelObj?.chain?.label?.show;

  const hideClasses = getHideClasses(
    layoutName,
    customFields,
    outputType,
    isAdmin
  );
  const tableDividerClasses = getDividerClasses(GRID_INFO, "tables");
  const featureDividerClasses = getDividerClasses(GRID_INFO, "features");

  const bottomSeparatorMap = {
    "Large with line": "large-bottom-separator line-bottom",
    Large: "large-bottom-separator no-line-bottom",
    "Small with line": "small-bottom-separator line-bottom",
    Small: "small-bottom-separator no-line-bottom",
    "X-small": "x-small-bottom-separator no-line-bottom",
    None: ""
  };
  const bottomSeparatorClassName = bottomSeparatorMap[bottomSeparator]
    ? bottomSeparatorMap[bottomSeparator]
    : "";

  /**
   *  render data
   *  {
   *    table1: [
   *      {flexFeature: child, styleVar: col#, key: 1}
   *    ],
   *    table9: [
   *      {flexFeature: child, styleVars: N/A?, key: 1} //order per table starts at 0
   *    ]
   *    ...
   * }
   */
  const allTableAllChildData = createRenderDataStructure(
    GRID_INFO,
    children,
    layoutObj,
    itemsPerFeatureMap,
    allFeatureIds,
    gridObj
  );

  // get validation for admin users
  const colValidation = validateTopTable(
    GRID_INFO,
    isAdmin && !hideValidationMsg,
    layoutName,
    layoutObj,
    itemsPerColumn
  );

  const unwebbedFeatureIndices = findUnwebbedFeatureIndices(
    outputType,
    childProps,
    showHelpers
  );

  const hiddenFeatureIndices = findHiddenFeatureIndices(
    outputType,
    desktopOrderingObj[bp],
    childProps,
    showHelpers
  ).filter((idx) => !unwebbedFeatureIndices.includes(idx));

  const unassignedChildrenIds = findMissingChildrenIds(
    allFeatureIds,
    itemsPerFeatureMap,
    childProps,
    showHelpers
  ).filter(
    (id) =>
      !hiddenFeatureIndices.includes(id - 1) &&
      !unwebbedFeatureIndices.includes(id - 1)
  );

  const isFullSpan = layoutName.match(/full span/i);

  const showUnassigned =
    isAdmin && unassignedChildrenIds.length > 0 && !isFullSpan;
  const unassignedLayout = showUnassigned && layoutStyles.Tiling;
  const unassignedTableKey = showUnassigned && "allcontent";

  const showHelpLabels = isAdmin && showHelpers;

  const tableKeyDisplayNames = {
    table0: "top strip",
    table1: "table 1",
    table2: "table 2",
    table3: "table 3",
    table4: "table 4",
    table5: "table 5",
    table6: "table 6",
    table7: "table 7",
    table8: "table 8",
    tableAd1: "ad table 1",
    table9: "far-right table"
  };

  const colDisplayNames = {
    left: "col 1",
    main: "col 2",
    right: "col 3"
  };

  // NOTE: generates data-link-group attribute as appropriate for itid click tracking
  const attrLinkGroupForChain = getLinkGroupAttr(
    get(customFields, "linkGroup"),
    labelObj.chain
  );

  const fullColSpan = GRID_INFO.reduce((acc, info) => {
    if (info.bp === bp) acc = info.fullColSpan;
    return acc;
  }, 20);

  const chainLabelClasses = `chain-label-${getLabelArrangement(fullColSpan)}`;

  const chain = (
    <Fragment>
      {isAdmin && hiddenFeatureIndices.length > 0 ? (
        <ChainProvider
          value={{
            ...customFields,
            isUnassigned: true
          }}
        >
          <HiddenSection
            features={children.filter((o, i) =>
              hiddenFeatureIndices.includes(i)
            )}
            indices={hiddenFeatureIndices}
            breakpointName={desktopOrderingObj[bp] ? "desktop" : "mobile"}
            chainCustomFields={customFields}
          />
        </ChainProvider>
      ) : null}
      {isAdmin && unwebbedFeatureIndices.length > 0 ? (
        <ChainProvider
          value={{
            ...customFields,
            isUnassigned: true
          }}
        >
          <HiddenSection
            features={children.filter((o, i) =>
              unwebbedFeatureIndices.includes(i)
            )}
            indices={unwebbedFeatureIndices}
            breakpointName="web"
            chainCustomFields={customFields}
          />
        </ChainProvider>
      ) : null}
      <ChainProvider
        value={{
          ...customFields,
          assignedLinkGroup: attrLinkGroupForChain["data-link-group"]
        }}
      >
        <div
          className={`hpgrid-max-width ma-auto ${hideClasses} ${
            className || ""
          }`}
          data-chain-name={displayName || "no-name"}
          data-gtm-module={displayName || "no-name"}
          {...dataAttrs}
          {...attrLinkGroupForChain}
          {...restOfInteractions}
          ref={ref}
          style={{
            ...style,
            ...spartanAdminStyles,
            position: "relative"
          }}
        >
          {isAdmin && (
            <IllegalPlacementWarning
              featureLabel={
                displayName !== TopTableLabel && !/no-name/.test(displayName)
                  ? `The chain ${displayName}`
                  : `This ${TopTableLabel}`
              }
              placementRequirements={{ inZone: ["main-content"] }}
            />
          )}
          {overlayLeft}
          {overlay}
          {labelObj.chain && labelObj.chain.label.show && (
            <div
              className={`mr-sm ml-sm mr-0-ns ml-0-ns label ${chainLabelClasses}`}
            >
              <CompoundLabel {...labelObj.chain} />
            </div>
          )}
          <div
            className={getClasses(
              `chain hpgrid hpgrid-max-width ma-auto ${desktopOrderingClasses} ${tableDividerClasses} ${extraRowClassNames}`,
              {
                [`${bottomSeparatorClassName}`]: !hasChainCta
              }
            )}
            style={{ ...extraRowStyles, ...gridGapStyles }}
          >
            {Object.keys(allTableAllChildData).map((tableKey, index) => {
              const itm = layoutObj[tableKey];
              const { labelStyles, childStyles } = itm;
              const positionClasses = getPositionClasses(
                GRID_INFO,
                tableKey,
                "chain",
                chainGridObj
              );
              const tableValidation = colValidation[`${tableKey}.childStyles`];
              const { msg: tableMsg } = tableValidation || {};

              // NOTE: generates data-link-group attribute as appropriate for itid click tracking
              const attrLinkGroup = getLinkGroupAttr(
                get(customFields, `${tableKey}LinkGroup`),
                labelObj[tableKey]
              );
              const shouldRender =
                allFeatureIdsByTable[tableKey].length > 0 || isAdmin;
              if (!shouldRender) return null;

              const tableLabelClasses = `table-label-${getLabelArrangement(
                itm.styles[`--c-span-${bp}`]
              )}`;

              const tableRef = tableKeyRefs[tableKey];

              return (
                <div
                  key={tableKey}
                  ref={tableRef}
                  {...attrLinkGroup}
                  className={getClasses(
                    `table-in-grid hpgrid hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans ${kebabcase(
                      layoutName
                    )}-layout ${positionClasses} ${tableKey} ${featureDividerClasses}`,
                    {
                      "hide-helpers": !showHelpLabels,
                      "show-helper-colors": showHelpLabels
                    }
                  )}
                  style={layoutObj[tableKey].styles}
                >
                  {tableMsg && ChainColumnDropTarget && (
                    <div
                      className="admin-info hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans gray-dark"
                      style={{
                        ...(labelStyles || childStyles)
                      }}
                    >
                      <ChainColumnDropTarget colId={`${tableKey}LeftIds`} />
                    </div>
                  )}
                  {allFeatureIdsByTable[tableKey].length > 0 &&
                    labelObj[tableKey] &&
                    labelObj[tableKey].label.show && (
                      <div
                        className={`hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-close-the-gap ${tableLabelClasses}`}
                        style={{
                          ...(labelStyles || childStyles)
                        }}
                      >
                        <CompoundLabel {...labelObj[tableKey]} />
                      </div>
                    )}
                  {isAdmin &&
                    Object.keys(
                      layoutObj[tableKey].columns || { left: true }
                    ).map((col) => {
                      const classNames =
                        "admin-info feature hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans font-xxs gray-dark";
                      const isEmpty = !get(
                        customFields,
                        `${tableKey}${capitalize(col)}Ids`,
                        ""
                      ).length;

                      const styleKey = get(
                        layoutObj,
                        `${tableKey}.columns.${col}`
                      )
                        ? `columns.${col}`
                        : "childStyles";
                      const isTiling = !get(
                        layoutObj,
                        `${tableKey}.${styleKey}["--c-start-${bp}"]`
                      );
                      const isShallowDropTarget =
                        (/table9/.test(tableKey) &&
                          /Stacked strips.*(and|with) \d+-col far-right table/.test(
                            layoutName
                          )) ||
                        (!/table9/.test(tableKey) &&
                          /(Double wide|Stacked strips)/.test(layoutName)) ||
                        (!/table(1|9)/.test(tableKey) &&
                          /Extra-wide center - 3 separate tables/.test(
                            layoutName
                          ));

                      const showInTableAdReminder =
                        !showHelpers &&
                        isAdmin &&
                        /tableAd/.test(tableKey) &&
                        !allTableAllChildData[tableKey].length;

                      return (
                        <Fragment key={`${tableKey}-${col}`}>
                          {(showHelpLabels || showInTableAdReminder) && (
                            <div
                              className={getClasses(classNames, {
                                [`show-helper-colors ${tableKey}`]: false,
                                "w-100": true
                              })}
                              style={{
                                ...get(layoutObj, `${tableKey}.${styleKey}`),
                                position: "absolute",
                                top: "-26px",
                                color: "#000",
                                background: "#FFF"
                              }}
                            >
                              {showHelpLabels &&
                                `${
                                  tableKeyDisplayNames[tableKey] || tableKey
                                } | ${
                                  colDisplayNames[col] || col
                                } | rows: ${getTotalRowSpansPerTableColumn({
                                  customFields,
                                  childProps,
                                  tableKey,
                                  col
                                })}`}
                              {showInTableAdReminder && (
                                <Warning
                                  message={
                                    "Reminder: An ad or other content appears here on mobile. Keep this in mind as you package stories."
                                  }
                                  level="warning"
                                />
                              )}
                            </div>
                          )}
                          {!/table0/.test(tableKey) &&
                            tableKeyDisplayNames[tableKey] &&
                            (!isShallowDropTarget || isEmpty) &&
                            !/^(xs|sm)$/.test(bp) && (
                              <div
                                key={`${tableKey}_${col}_dropzone`}
                                className={classNames}
                                style={{
                                  ...(isEmpty && isTiling
                                    ? get(layoutObj, `${tableKey}.styles`)
                                    : get(
                                        layoutObj,
                                        `${tableKey}.${styleKey}`
                                      )),
                                  "--dsktp-order": 1000,
                                  position: "relative",
                                  minHeight: isEmpty ? 100 : 0
                                }}
                              >
                                <ChainColumnDropTarget
                                  isEmpty={isEmpty}
                                  colId={`${tableKey}${capitalize(col)}Ids`}
                                  style={
                                    isShallowDropTarget
                                      ? { maxHeight: 100 }
                                      : {}
                                  }
                                />
                              </div>
                            )}
                        </Fragment>
                      );
                    })}
                  {allTableAllChildData[tableKey].map((item, featureIndex) => (
                    <Fragment key={`${item.key}-frag`}>
                      <SandwichLayoutProvider
                        key={item.key}
                        value={{
                          isUnassigned: false,
                          layoutName,
                          isFullSpan,
                          tableKey,
                          positionClasses: item.positionClasses,
                          positionClassesArray: item.positionClassesArray,
                          layoutVars: item.styleVars,
                          layoutClasses,
                          itemId: item.itemId,
                          showFeatureName: showHelpLabels && showFeatureNames,
                          visitedLinks
                        }}
                      >
                        <TableLayoutProvider
                          value={{
                            assignedLinkGroup: attrLinkGroup["data-link-group"],
                            updateItemsPerFeatureMapFeedAdjustments,
                            updateItemsPerFeatureMapDQedAdjustments,
                            featureId: item.itemId,
                            itemsPerFeature: itemsPerFeatureMap[item.itemId],
                            index: index + featureIndex,
                            ref: tableRef
                          }}
                        >
                          {item.feature}
                        </TableLayoutProvider>
                      </SandwichLayoutProvider>
                    </Fragment>
                  ))}
                  {allFeatureIdsByTable[tableKey].length > 0 &&
                    ctaLabelObj[tableKey] &&
                    ctaLabelObj[tableKey].label.show && (
                      <div
                        className={`table-cta hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans ${tableLabelClasses}`}
                        style={{
                          "--dsktp-order": 999,
                          marginTop: "calc(-0.5 * var(--feature-row-gap))",
                          ...(labelStyles || childStyles)
                        }}
                      >
                        <CompoundLabel {...ctaLabelObj[tableKey]} />
                      </div>
                    )}
                </div>
              );
            })}
          </div>
          {ctaLabelObj.chain && ctaLabelObj.chain.label.show && (
            <div
              className={`chain-cta mt-sm mr-sm ml-sm mr-0-ns ml-0-ns label ${bottomSeparatorClassName} ${chainLabelClasses}`}
            >
              <CompoundLabel {...ctaLabelObj.chain} />
            </div>
          )}
        </div>
      </ChainProvider>
      {showUnassigned && (
        <ChainProvider
          value={{
            ...customFields,
            isUnassigned: true
          }}
        >
          <UnassignedZone
            displayName={displayName}
            unassignedTableKey={unassignedTableKey}
            unassignedLayout={unassignedLayout}
            unassignedChildrenIds={unassignedChildrenIds}
            itemsPerFeatureMap={itemsPerFeatureMap}
            childProps={childProps}
            outputType={outputType}
          >
            {children}
          </UnassignedZone>
        </ChainProvider>
      )}
    </Fragment>
  );

  if (isAdmin && hideChain) {
    const hideChainMsg = get(hiddenChainInfo[bp], "msg", "");
    return (
      <HiddenChain displaySize={hideChainMsg} displayName={displayName}>
        {chain}
      </HiddenChain>
    );
  }
  return chain;
};

TopTable.label = TopTableLabel;
TopTable.propTypes = TopTablePropTypes;
export default TopTable;
